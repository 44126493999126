import Parser from "html-react-parser"
import React, { useEffect } from "react"
import * as styles from "./LeadooDynamicEmbedding.module.scss"

type LeadooDynamicEmbeddingProps = {
  hasMargin?: boolean
  leadooSlotScript: string
}

const handleCookieConsent = () => {
  window.addEventListener(
    "CookiebotOnAccept",
    function () {
      if (window.Cookiebot?.consent?.statistics) {
        console.log("Statistics consent")
        if (!window.ldanalytics) window.ldanalytics = []
        window.ldanalytics.push(function (a) {
          a.toggleTracking(true, false)
        })
      }
    },
    false
  )
}

const LeadooDynamicEmbedding = (props: LeadooDynamicEmbeddingProps) => {
  const parsedHTML = Parser(props.leadooSlotScript)

  useEffect(() => {
    handleCookieConsent()
  }, [])

  return <div className={`${props.hasMargin && styles.margins}`}>{parsedHTML}</div>
}

export default LeadooDynamicEmbedding
