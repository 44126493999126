import { graphql } from "gatsby"
import React from "react"
import LeadooDynamicEmbedding from "../../molecules/LeadooDynamicEmbedding"

export type DatoLeadooDynamicEmbeddingkProps = {
  hasMargins?: boolean
  leadooSlotScript: string
}

const DatoLeadooDynamicEmbedding = (props: Queries.DatoLeadooDynamicEmbeddingFragment) => {
  return <LeadooDynamicEmbedding hasMargin={props.hasMargin} leadooSlotScript={props.leadooSlotScript} />
}

export default DatoLeadooDynamicEmbedding

export const query = graphql`
  fragment DatoLeadooDynamicEmbedding on DatoCmsLeadooDynamicEmbedding {
    id: originalId
    hasMargin
    leadooSlotScript
  }
`
